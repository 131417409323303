<template>
    <div class="wrapper-landing">
      <LandingHero
        title="Haz una lista de deseos para tu bebé y participa en el sorteo"
        classNameCover="simple"
        buttonText="Empezar"
        origin="sorteo"
      >
        <template slot="cover">
          <source
            media="(max-width: 991px)"
            class=""
            srcset="../../assets/img/landings/cover-box-sorteo-resp.jpg"
            alt=""
            loading="lazy"
          />
          <source
            media="(max-width: 1399px)"
            class=""
            srcset="../../assets/img/landings/cover-box-sorteo-md.jpg"
            alt=""
            loading="lazy"
          />
          <img
            class="ln-hero__inset"
            src="../../assets/img/landings/cover-box-sorteo-@2x.jpg"
            alt=""
            loading="lazy"
          />
        </template>
      </LandingHero>
  
      <section class="hbb-features">
      <div class="bb-container">
        <div class="hbb-features__title">
          HelloBB es tu lista de nacimiento <span>online</span>
        </div>
        <div class="hbb-features__items">
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-list-heart.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">La más bonita y personal</div>
              <div class="hbb-features__sh">
                Este momento único se merece algo a la altura.
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-click-mobile.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">Fácil de usar para todos</div>
              <div class="hbb-features__sh">
                Zero problemas para ti y la gente con la que compartas tus deseos.
              </div>
            </div>
          </div>
          <div class="hbb-features__item">
            <div class="hbb-features__ico">
              <img loading="lazy" src="../../assets/img/ico/ico-color-gratis.svg" />
            </div>
            <div class="hbb-features__footer">
              <div class="hbb-features__h">Gratis y sin compromisos</div>
              <div class="hbb-features__sh">
                Haz tu lista gratis y sin ninguna obligación de comprar nada.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="app-avanzada">
      <div class="bb-container">
        <div class="app-avanzada__container">
        <div class="app-avanzada__left">
          <div class="app-avanzada__title">Una App con todo lo que necesitas</div>
          <div class="app-avanzada__description">
            Haz tu lista como prefieras, desde la comodidad de tu casa o con la flexibilidad de tu móvil.
          </div>
          <div class="app-avanzada__actions">
            <!-- <a href="#" class="link link--bold link--underline">
              Descubre nuestra App gratuita
            </a> -->
            Descubre nuestra App gratuita con todo lo que necesitas para hacer tu lista perfecta.
          </div>
          <div class="app-avanzada__stores">
            <a href="#">
              <img src="../../assets/img/logos/logo-app-google.svg" alt="" />
            </a>
            <a href="#">
              <img src="../../assets/img/logos/logo-app-appstore.svg" alt="" />
            </a>
          </div>
        </div>
        <picture class="app-avanzada__cover">
            <img
              src="../../assets//img/covers/mockup_lista_ES.png"
              alt=""
            />
        </picture>
      </div>
      </div>
    </section>
    <main class="home-beneficios">
      <div class="bb-container">
        <div class="home-beneficios__items">
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img src="../../assets/img/covers/cover-fr-heart-sale.png" alt="" />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Elige cualquier producto de cualquier tienda
              </div>
              <div class="home-beneficios__description">
                Tienes libertad total para escoger justo lo que quieres. 
              </div>
              <ul class="home-beneficios__list">
                <li>Productos únicos de pequeñas marcas</li>
                <li>Las mejores ofertas de las grandes tiendas</li>
              </ul>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img
                src="../../assets//img/covers/cover-fr-recibiendo-dinero-es.jpg"
                alt="Prête pour recevoir de l'argent dans votre cagnotte."
              />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Lista para recibir dinero en tu hucha
              </div>
              <div class="home-beneficios__description">
                Tu lista de nacimiento incorpora una hucha con la que podrás reunir dinero
                para compras concretas o para causas más generales.
              </div>
            </div>
          </section>
          <section class="home-beneficios__item">
            <div class="home-beneficios__cover">
              <img
                src="../../assets//img/covers/mockup_aportaciones_ES.png"
                alt="Offrez la possibilité de se cotiser pour les achats les plus chers"
              />
            </div>
            <div class="home-beneficios__right">
              <div class="home-beneficios__title">
                Ofrece la posibilidad de compartir las compras más caras
              </div>
              <div class="home-beneficios__description">
                Además, tu familia y amigos podrán colaborar con aportaciones parciales
                para comprar las cosas más caras entre todos.
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  
      <LandingSorteoSection>
        <template slot="titulo">Participa en el sorteo de nuestra HelloBB Box</template>
        <template slot="descripcion">
          <p>
            La HelloBB Box es nuestra forma de dar la bienvenida a los nuevos usuarios y a
            sus futuros peques presentándoles nuestras marcas y productos favoritos.
            Sorteamos 10 cada mes.
          </p>
          <p>
            Los regalos que van dentro de la caja son <strong>sorpresa</strong> y varían de
            forma frecuente. Podría haber desde chupetes, baberos, pañales, toallitas,
            gorros y también cupones descuento y cheques regalo. La caja HelloBB Box suele
            estar valorada en unos <strong>50€</strong>.
          </p>
        </template>
        <template slot="imagen">
          <img src="../../assets/img/covers/cover-sorteo.png" alt="" />
        </template>
      </LandingSorteoSection>
  
      <main class="bg-cercles">
        <section class="bb-container">
          <LandingSorteoBases>
            <template slot="titulo">Condiciones del sorteo</template>
            <template slot="descripcion">
              <p>
                Para entrar en la promoción, debes hacer tu lista con HelloBB (no solo
                empezarla 😉). Esto significa:
              </p>
              <ol>
                <li>
                  <strong>Abrir tu lista y poner al menos 10 cosas en ella (pueden ser de cualquier tienda).</strong>
                </li>
                <li>
                  <strong>Compartirla con tus allegados y que te regalen algo de lo que pusiste.</strong>
                </li>
                <li>
                  <strong>Ser mayor de edad y residir en España.</strong>
                </li>
              </ol>
              <p>
                Sorteamos 10 HelloBB Box entre los usuarios registrados cada mes que cumplan
                las anteriores condiciones. Entrarás en el sorteo del mes en el que las cumplas todas (no importa si te registras un mes y te hacen los regalos unos meses después). 
              </p>
                <p>
                El sorteo se realizará cada día 1 (para los usuarios del mes anterior).
              </p>
              <p>
                Comunicaremos los ganadores al email facilitado al crear la lista. <br />
              </p>
            </template>
          </LandingSorteoBases>
          <LandingReviews />
          <LandingHablan showToTop="true" />
        </section>
      </main>
      <LandingFooter />
    </div>
  </template>
  
  <script>
  import LandingFeaturesSimple from "./components/LandingFeaturesSimple.vue";
  import LandingFeaturesSimpleItem from "./components/LandingFeaturesSimpleItem.vue";
  import LandingHero from "./components/LandingHero.vue";
  import LandingAppRatings from "./components/LandingAppRatings.vue";
  import LandingReviews from "./components/LandingReviews.vue";
  import LandingHablan from "./components/LandingHablan.vue";
  import LandingFooter from "./components/LandingFooter.vue";
  
  import LandingComoList from "./components/LandingComoList.vue";
  import LandingComoListItem from "./components/LandingComoListItem.vue";
  
  import LandingComoCols from "./components/LandingComoCols.vue";
  import LandingComoColsItem from "./components/LandingComoColsItem.vue";
  
  import LandingSorteoSection from "./components/LandingSorteoSection.vue";
  import LandingSorteoBases from "./components/LandingSorteoBases.vue";
  
  export default {
    name: "LandingSorteo2",
    metaInfo() {
      return {
        title: "Sorteo HelloBB Box",
        meta: [
          { name: "description", content: "Sorteo HelloBB Box" },
          { name: "robots", content: "noindex" },
        ],
      };
    },
    components: {
      LandingAppRatings,
      LandingHablan,
      LandingReviews,
      LandingFooter,
      LandingHero,
      LandingFeaturesSimple,
      LandingFeaturesSimpleItem,
      LandingComoList,
      LandingComoListItem,
      LandingComoCols,
      LandingComoColsItem,
      LandingSorteoSection,
      LandingSorteoBases,
    },
  };
  </script>